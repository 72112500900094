import React from 'react'
import Layout from '../../../templates/layout'
import Seo from '../../../helpers/seo'
import CTA from '../../../components/organisms/CTA'
import CasesRelational from '../../../components/organisms/CasesRelational'
import Content from './content'
import Banner from '../../../components/molecules/Banner'
import content from './content.json'

const { relational } = content

const LocTok = () => {
  return (
    <Layout page="LocTok">
      <Banner image="loctok-banner.webp" />
      <Content />
      <CTA />
      <CasesRelational relationalData={relational} itsRelationalCase />
    </Layout>
  )
}

export const Head = () => <Seo title="LocTok" />

export default LocTok
